<template>
    <div class="page-container">
        <div class="page-panel --main">
            <slot />
        </div>
        <div class="page-panel --side">
            <slot name="sidePanel" />
        </div>
    </div>
</template>
